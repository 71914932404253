import React from "react"
import Layout from "../components/layout"
import { Box, Center, Text, Image } from "@chakra-ui/react"
import anne from "../media/frontal.svg"

export default function Home() {
  return (
    <Layout title="Contact" slug="contact/">
         <Text mb={3}>
      Seit 2019 teile ich meinen Yogaweg mit leidenschaftlichem Interesse für die Gesundheit.
      Daher bildet das Wissen über anatomische, physiologische und pathologische 
      Körpervorgänge die Basis meiner Yogastunden. 
      <br />
      <br />
      Mit Yoga verbinde ich auch eine Art Lebensphilosophie, die das persönliche Potential und die 
      Selbstwirksamkeit bewusst werden lässt.
      </Text>
      <br />
      <Text mb={3} as="i">
        Auf der Suche nach einem Ausgleich zu monotonen Alltagsbewegungen?
        <br />
        Auf der Suche nach Meditations- und Entspannungstechniken zur Regeneration?
        <br />
        Auf der Suche nach Yoga bei individuellen Vorerkrankungen oder verheilten Verletzungen?
        <br />
        Gibt es den Wunsch eine individuelle Yoga Routine für zu Hause aufzubauen?
        <br />
        Interesse an Austausch und Zusammenarbeit?
        <br />
      </Text>
      <br />
      <Text mb={3}> Ich freue mich über eine Nachricht an hello.annerieck@gmail.com</Text>
      <Center>
        <Box boxSize="md">
          <Image mt={10} src={anne} alt="Illustration von Anne" />
        </Box>
      </Center>
    </Layout>
  )
}
